@import '_reset';
@import '_media-queries';

$orange: #f57f3b;
$transTime: 0.5s;

@font-face {
	font-family: 'FKG';
	src: url('../fonts/FKGrotesk-Bold.woff2') format('woff2'), url('../fonts/FKGrotesk-Bold.woff') format("woff");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'FKG';
	src: url('../fonts/FKGrotesk-BoldItalic.woff2') format('woff2'), url('../fonts/FKGrotesk-BoldItalic.woff') format("woff");
	font-weight: 700;
	font-style: italic;
}

$minibar: 32px;

* {
	box-sizing: border-box;
}

html {
	font-size: 19px;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
}

body {
	color: black;
	background-color: white;
	font-family: 'FKG', 'Arial', sans-serif;
	font-weight: 700;
	font-feature-settings: "ss03" 1, "ss09" 1, "ss20" 1; 
	font-size: 1rem;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

i, em {
	font-style: italic;
}

a {
	color: inherit;
	text-decoration: none;
}

img {
	display: block;
	width: auto;
	height: auto;
}

nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 32px;
	height: 100%;
	color: black;
	background-color: white;
	z-index: 998;

	border-right: 1px solid black;

	font-size: 0.7rem;

	@include min-screen-height(660px) {
		font-size: 1rem;
	}

	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		right: 100%;
		width: 100vw;
		height: 100%;
		background-color: black;
	}
	
	a#close {
		position: absolute;
		top: -32px;
		left: 0;
		width: 32px;
		height: 32px;
		text-align: center;
		line-height: 30px;
		color: $orange;
		cursor: pointer;
		transform: translateY(0);

		&:hover {
			color: black;
		}
	}

	ul {
		transform-origin: top left;
		transform: rotate(90deg) translateY(-100%);
		width: auto;
		height: 32px;
		padding: 0 10px;
		line-height: 32px;
		text-transform: uppercase;
		white-space: nowrap;

		li {
			display: inline-block;

			& + li {
				margin-left: 10px;
			}
		}
	}

	a {
		transition: color 150ms;

		&:hover {
			color: $orange;
		}
	}

	& > a {
		display: block;
		width: 32px;
		height: 32px;
		position: absolute;
		left: 0;
		text-align: center;
		line-height: 32px;
	}

	& > a#fcbk {
		bottom: 4px;
	}

	& > a#insta {
		bottom: 36px;
	}
}

#mapcontainer {
	position: fixed;
	top: 0;
	left: $minibar;

	width: calc(100vw - #{ $minibar });
	height: 100%;
	overflow: hidden;

	#map, #o-p-e-n {
		position: fixed;
		top: 0;
		left: $minibar;
		width: calc(100vw - #{ $minibar });
	}

	#map {
		height: calc(100% + 50px);
	}

	#o-p-e-n {
		pointer-events: none;
		height: 100%;

		.os-ltr {
			position: absolute;
			width: calc(8vw + 20px);
			padding: 5px;

			&#ltr-O {
				top: 0;
				left: 0;
			}

			&#ltr-P {
				top: 0;
				right: 0;
				
			}

			&#ltr-E {
				bottom: 1rem;
				left: 0;

				@include min-screen(400px) {
					bottom: 1.5rem;
				}

				@include min-screen(600px) {
					bottom: 2.1rem;
				}
			}

			&#ltr-N {
				bottom: 1rem;
				right: 0;

				@include min-screen(400px) {
					bottom: 1.5rem;
				}

				@include min-screen(600px) {
					bottom: 2.1rem;
				}
			}
		}

		& > div {
			position: absolute;
			bottom: calc(5px + 1.15vw);
		}

		#info-A {
			left: calc(5px + 1.15vw);
		}

		#info-B {
			right: calc(5px + 1.15vw);
			text-align: right;
			word-spacing: 0.1em;
		}

		#info-A, #info-B {
			font-size: 0.7rem;

			span {
				display: none;

				@include min-screen(400px) {
					display: inline-block;
				}
			}

			@include min-screen(600px) {
				font-size: 1rem;
			}
		}
	}
}

#locations {
	position: fixed;
	top: 0;
	left: $minibar;
	z-index: 997; 

	width: calc(100vw - #{ $minibar });
	height: 100%;
	overflow: hidden;

	color: black;
	background-color: white;
	border-right: 1px solid black;

	@include min-screen(600px) {
		width: calc((100vw - #{ $minibar }) / 2);
	}

	@include min-screen(900px) {
		width: calc((100vw - #{ $minibar }) / 3);
	}

	@include min-screen(1200px) {
		width: calc((100vw - #{ $minibar }) / 4);
	}

	@include min-screen(1600px) {
		width: calc((100vw - #{ $minibar }) / 5);
	}

	.location {
		pointer-events: none;

		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;
		
		overflow: hidden;

		.scroll {
			position: absolute;
			top: 0;
			left: 0;
			width: calc(100% + 50px);
			height: 100%;

			overflow-x: hidden;
			overflow-y: auto;

			//-webkit-overflow-scrolling: touch;

			&::-webkit-scrollbar {
				display: none;
			}

			.wrap {
				width: calc(100vw - #{ $minibar });

				@include min-screen(600px) {
					width: calc((100vw - #{ $minibar }) / 2);
				}

				@include min-screen(900px) {
					width: calc((100vw - #{ $minibar }) / 3);
				}

				@include min-screen(1200px) {
					width: calc((100vw - #{ $minibar }) / 4);
				}

				@include min-screen(1600px) {
					width: calc((100vw - #{ $minibar }) / 5);
				}
			}
		}
	}

	.header {
		display: flex;
		flex: 0 0 auto;

		div {
			position: relative;
			width: 50%;
			height: 50vw;
			padding: 10px;

			&:last-child {
				border-left: 1px solid black;
			}

			@include min-screen(600px) {
				height: 25vw;
			}

			@include min-screen(900px) {
				height: 16.666vw;
			}

			@include min-screen(1200px) {
				height: 12.5vw;
			}

			@include min-screen(1600px) {
				height: 10vw;
			}

			span {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 30vw;
				height: 30vw;
				margin: -15vw 0 0 -15vw;
				border-radius: 100%;

				color: white;
				background-color: black;

				font-size: 12.5vw;
				line-height: 29vw;
				text-align: center;

				@include min-screen(600px) {
					width: 16vw;
					height: 16vw;
					margin: -8vw 0 0 -8vw;
					font-size: 7.5vw;
					line-height: 15.25vw;
				}

				@include min-screen(900px) {
					width: 12vw;
					height: 12vw;
					margin: -6vw 0 0 -6vw;
					font-size: 5.5vw;
					line-height: 11.5vw;
				}

				@include min-screen(1200px) {
					width: 8.5vw;
					height: 8.5vw;
					margin: -4.25vw 0 0 -4.25vw;
					font-size: 4vw;
					line-height: 8.25vw;
				}

				@include min-screen(1600px) {
					width: 7vw;
					height: 7vw;
					margin: -3.5vw 0 0 -3.5vw;
					font-size: 3.5vw;
					line-height: 6.85vw;
				}		
			}
		}
	}

	.content {
		& > div {
			width: 100%;
			height: auto;
			padding: 10px;
			border-top: 1px dotted rgba(0,0,0,0.25);

			span.label {
				display: block;
				text-transform: uppercase;

				font-size: 0.7rem;
				letter-spacing: 0.035em;

				.icon {
					padding-right: 0.25rem;
				}

				margin-bottom: 1rem;
			}

			p {
				margin-bottom: 1rem;

				&:last-child {
					margin-bottom: 0;
				}
			}

			&.image {
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				flex: 0 1 100%;
				height: 50vw;
				max-height: 480px;
				padding: 0;

				background-position: center center;
				background-repeat: norepeat;

				border-top: 1px solid black;
				border-bottom: 1px solid black;

				@include min-screen(900px) {
					height: 33.333vw;
				}

				@include min-screen(1200px) {
					height: 25vw;
				}

				@include min-screen(1600px) {
					height: 20vw;
				}

				a {
					display: block;
					top: 50%;
					left: 50%;
					width: auto;
					height: 2rem;
					border-radius: 1.5rem;

					padding: 0 0.85rem;

					color: white;
					background-color: black;

					font-size: 0.7rem;
					letter-spacing: 0.035em;
					text-transform: uppercase;
					line-height: 2rem;

					.icon {
						padding-right: 0.25rem;
					}
				}

				& + div {
					border-top: none;
				}
			}
		}
	}
}

#program, #list, #press {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	width: calc(100vw - #{ $minibar });
	height: 100%;
	overflow: hidden;
	
	color: white;
	background-color: black;
	line-height: 1.05;

	@include min-screen(600px) {
		width: calc(50vw - #{ $minibar });
	}

	@include min-screen(900px) {
		width: calc(33.333vw - #{ $minibar });
	}

	@include min-screen(1200px) {
		width: calc(25vw - #{ $minibar });
	}

	@include min-screen(1600px) {
		width: calc(20vw - #{ $minibar });
	}

	.scroll {
		position: absolute;
		top: 0;
		left: 0;
		width: calc(100% + 50px);
		height: 100%;

		overflow-x: hidden;
		overflow-y: auto;

		//-webkit-overflow-scrolling: touch;

		&::-webkit-scrollbar {
			display: none;
		}

		.wrap {
			padding: 10px;
			width: calc(100vw - #{ $minibar });

			@include min-screen(600px) {
				width: calc(50vw - #{ $minibar });
			}

			@include min-screen(900px) {
				width: calc(33.333vw - #{ $minibar });
			}

			@include min-screen(1200px) {
				width: calc(25vw - #{ $minibar });
			}

			@include min-screen(1600px) {
				width: calc(20vw - #{ $minibar });
			}

			& > span {
				display: block;
				margin-bottom: 2rem;
				text-transform: uppercase;
			}
		}
	}
}

#list {
	ul {
		li {
			position: relative;
			margin-bottom: 1rem;

			&:last-child {
				margin-bottom: 0;
			}

			span {
				display: block;

				&:last-child {
					font-size: 0.7rem;
					margin-top: 0.15rem;
				}
			}
		}
	}
}

#program {
	.day-container {
		margin-bottom: 5rem;

		& > span {
			display: block;
			text-transform: uppercase;
			word-spacing: 0.1em;
			margin-bottom: 1.5rem;
		}
	}

	.event {
		margin-bottom: 1.5rem;

		& > div {
			&:first-child {
				text-transform: uppercase;
			}

			&:last-child {
				font-size: 0.7rem;
				line-height: 1.15;
				margin-top: 0.15rem;
			}

			strong {
				font-style: normal;
				text-decoration: underline;
			}

			p, ul, ol {
				max-width: 320px;
			}
		}
	}
}

#press {
	.wrap > div {
		margin-bottom: 2rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.downloads {
		font-size: 0.7rem;
		text-transform: uppercase;

		a {
			display: inline-block;
			margin-bottom: 0.25rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		svg {
			margin-right: 0.25rem;
		}
	}

	.contact {
		font-size: 0.7rem;
	}

	.about {
		p {
			text-indent: 1.25rem;

			&:first-child {
				text-indent: 0;
			}

			a {
				text-decoration: underline;
			}
		}
	}

	.partners {
		margin-top: 5rem;
		
		div {
			margin-bottom: 1.5rem;
		}

		img {
			display: inline-block;
			height: 44px;
			width: auto;
			margin-right: 1rem;
		}

		#logo-brno {
			height: 22px;
			margin-bottom: 0.25rem;
		}

		#logo-vut {
			height: 40px;
			margin-bottom: 0.25rem;
		}

		p {
			font-size: 0.7rem;
		}
	}
}


body {
	nav {
		transform: translateX(0);
		transition: transform 400ms cubic-bezier(.59,.06,.32,.99);

		a#close, ul {
			transition: transform 250ms 400ms, color 150ms;
		}
	}

	#mapcontainer {
		.os-ltr, #info-A, #info-B {
			opacity: 1;
			transition: opacity 600ms 800ms;
		}
	}

	#locations {
		transform: translateX(-100%);
		transition: transform 600ms cubic-bezier(.59,.06,.32,.99);

		.location {
			opacity: 0;
			transition: opacity 200ms 200ms;
		}
	}

	#list, #program, #press {
		transform: translateX(-100%);
		pointer-events: none;
		transition: transform 400ms cubic-bezier(.59,.06,.32,.99);
	}
}

body.locationopen,
body.listopen,
body.programopen,
body.pressopen {
	nav {
		a#close {
			transform: translateY(32px);
			transition: transform 200ms, color 150ms;
		}

		ul {
			transform: rotate(90deg) translateX(30px) translateY(-100%);
			transition: transform 200ms;
		}
	}
	#mapcontainer {
		.os-ltr, #info-A, #info-B {
			opacity: 0;
			transition: opacity 250ms;
		}
	}
}

body.locationopen {
	nav {
		transform: translateX(0);
		transition: transform 300ms cubic-bezier(.59,.06,.32,.99);
	}

	#locations {
		transform: translateX(0%);
		transition: transform 400ms cubic-bezier(.57,.01,.82,.99) 250ms;

		.location {
			transition: opacity 300ms;
		}

		.location.open {
			opacity: 1;
			transition: opacity 800ms 350ms;
			pointer-events: auto;
		}
	}

	#list, #program, #press {
		transition: transform 300ms cubic-bezier(.59,.06,.32,.99);
	}
}

body.listopen {
	nav {
		transform: translateX(calc(100vw - 32px));

		@include min-screen(600px) {
			transform: translateX(calc(50vw - 32px));
		}

		@include min-screen(900px) {
			transform: translateX(calc(33.333vw - 32px));
		}

		@include min-screen(1200px) {
			transform: translateX(calc(25vw - 32px));
		}

		@include min-screen(1600px) {
			transform: translateX(calc(20vw - 32px));
		}

		transition: transform 500ms cubic-bezier(.29,.24,.29,.99) 200ms;
	}

	#mapcontainer {
		.os-ltr, #info-A, #info-B {
			transition: opacity 250ms 150ms;
		}
	}

	#locations {
		transition: transform 200ms cubic-bezier(.57,.01,.82,.99);
	}

	#list {
		transform: translateX(0%);
		pointer-events: auto;
		transition: transform 500ms cubic-bezier(.29,.24,.29,.99) 200ms;
	}

	#program, #press {
		transition: transform 300ms cubic-bezier(.59,.06,.32,.99);
	}
}

body.programopen {
	nav {
		transform: translateX(calc(100vw - 32px));

		@include min-screen(600px) {
			transform: translateX(calc(50vw - 32px));
		}

		@include min-screen(900px) {
			transform: translateX(calc(33.333vw - 32px));
		}

		@include min-screen(1200px) {
			transform: translateX(calc(25vw - 32px));
		}

		@include min-screen(1600px) {
			transform: translateX(calc(20vw - 32px));
		}

		transition: transform 500ms cubic-bezier(.29,.24,.29,.99) 200ms;
	}

	#mapcontainer {
		.os-ltr, #info-A, #info-B {
			transition: opacity 250ms 150ms;
		}
	}

	#locations {
		transition: transform 200ms cubic-bezier(.57,.01,.82,.99);
	}

	#program {
		transform: translateX(0%);
		pointer-events: auto;
		transition: transform 500ms cubic-bezier(.29,.24,.29,.99) 200ms;
	}

	#list, #press {
		transition: transform 300ms cubic-bezier(.59,.06,.32,.99);
	}
}

body.pressopen {
	nav {
		transform: translateX(calc(100vw - 32px));

		@include min-screen(600px) {
			transform: translateX(calc(50vw - 32px));
		}

		@include min-screen(900px) {
			transform: translateX(calc(33.333vw - 32px));
		}

		@include min-screen(1200px) {
			transform: translateX(calc(25vw - 32px));
		}

		@include min-screen(1600px) {
			transform: translateX(calc(20vw - 32px));
		}

		transition: transform 500ms cubic-bezier(.29,.24,.29,.99) 200ms;
	}

	#mapcontainer {
		.os-ltr, #info-A, #info-B {
			transition: opacity 250ms 150ms;
		}
	}

	#locations {
		transition: transform 200ms cubic-bezier(.57,.01,.82,.99);
	}

	#press {
		transform: translateX(0%);
		pointer-events: auto;
		transition: transform 500ms cubic-bezier(.29,.24,.29,.99) 200ms;
	}

	#program, #list {
		transition: transform 300ms cubic-bezier(.59,.06,.32,.99);
	}
}

.marker {
	width: 38px;
	height: 38px;
	background-color: black;
	cursor: pointer;

	span {
		display: block;
		position: absolute;
		top: 7px;
		left: 7px;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: #fff;
		
		font-family: 'FKG', 'Arial', sans-serif;
		font-feature-settings: "ss20" 1; 
		font-weight: 700;
		font-size: 0.7rem;
		line-height: 22px;
		text-align: center;
	}

	// temporary
	p {
		transform: translate(0, -22px);
		text-align: center;
	}
}

.pswp__ui--fit .pswp__top-bar,
.pswp__button--arrow--left:before,
.pswp__button--arrow--right:before {
	background-color: transparent;
}

.pswp__img--placeholder--blank {
	background: transparent;
}

.pswp__preloader {
	top: 50%;
	left: 50%;
	margin: -22px 0 0 -22px;
}

.pswp--zoom-allowed .pswp__img {
	cursor: default;
}

.pswp__counter {
	left: 50%;
	transform: translateX(-50%);
	font-weight: 700;
	font-size: 0.7rem;
	opacity: 1;
}

.pswp__button {
	opacity: 1;
}

.pswp__button--close {
	position: absolute;
	bottom: 0;
	left: 50%;
	margin-left: -22px;
}

.pswp img {
	padding: 44px;
}


