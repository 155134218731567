/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: 'FKG';
  src: url("../fonts/FKGrotesk-Bold.woff2") format("woff2"), url("../fonts/FKGrotesk-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'FKG';
  src: url("../fonts/FKGrotesk-BoldItalic.woff2") format("woff2"), url("../fonts/FKGrotesk-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 19px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

body {
  color: black;
  background-color: white;
  font-family: 'FKG', 'Arial', sans-serif;
  font-weight: 700;
  font-feature-settings: "ss03" 1, "ss09" 1, "ss20" 1;
  font-size: 1rem;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

i, em {
  font-style: italic;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  display: block;
  width: auto;
  height: auto;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 32px;
  height: 100%;
  color: black;
  background-color: white;
  z-index: 998;
  border-right: 1px solid black;
  font-size: 0.7rem;
}

@media screen and (min-height: 660px) {
  nav {
    font-size: 1rem;
  }
}

nav:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 100%;
  width: 100vw;
  height: 100%;
  background-color: black;
}

nav a#close {
  position: absolute;
  top: -32px;
  left: 0;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 30px;
  color: #f57f3b;
  cursor: pointer;
  transform: translateY(0);
}

nav a#close:hover {
  color: black;
}

nav ul {
  transform-origin: top left;
  transform: rotate(90deg) translateY(-100%);
  width: auto;
  height: 32px;
  padding: 0 10px;
  line-height: 32px;
  text-transform: uppercase;
  white-space: nowrap;
}

nav ul li {
  display: inline-block;
}

nav ul li + li {
  margin-left: 10px;
}

nav a {
  transition: color 150ms;
}

nav a:hover {
  color: #f57f3b;
}

nav > a {
  display: block;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0;
  text-align: center;
  line-height: 32px;
}

nav > a#fcbk {
  bottom: 4px;
}

nav > a#insta {
  bottom: 36px;
}

#mapcontainer {
  position: fixed;
  top: 0;
  left: 32px;
  width: calc(100vw - 32px);
  height: 100%;
  overflow: hidden;
}

#mapcontainer #map, #mapcontainer #o-p-e-n {
  position: fixed;
  top: 0;
  left: 32px;
  width: calc(100vw - 32px);
}

#mapcontainer #map {
  height: calc(100% + 50px);
}

#mapcontainer #o-p-e-n {
  pointer-events: none;
  height: 100%;
}

#mapcontainer #o-p-e-n .os-ltr {
  position: absolute;
  width: calc(8vw + 20px);
  padding: 5px;
}

#mapcontainer #o-p-e-n .os-ltr#ltr-O {
  top: 0;
  left: 0;
}

#mapcontainer #o-p-e-n .os-ltr#ltr-P {
  top: 0;
  right: 0;
}

#mapcontainer #o-p-e-n .os-ltr#ltr-E {
  bottom: 1rem;
  left: 0;
}

@media screen and (min-width: 400px) {
  #mapcontainer #o-p-e-n .os-ltr#ltr-E {
    bottom: 1.5rem;
  }
}

@media screen and (min-width: 600px) {
  #mapcontainer #o-p-e-n .os-ltr#ltr-E {
    bottom: 2.1rem;
  }
}

#mapcontainer #o-p-e-n .os-ltr#ltr-N {
  bottom: 1rem;
  right: 0;
}

@media screen and (min-width: 400px) {
  #mapcontainer #o-p-e-n .os-ltr#ltr-N {
    bottom: 1.5rem;
  }
}

@media screen and (min-width: 600px) {
  #mapcontainer #o-p-e-n .os-ltr#ltr-N {
    bottom: 2.1rem;
  }
}

#mapcontainer #o-p-e-n > div {
  position: absolute;
  bottom: calc(5px + 1.15vw);
}

#mapcontainer #o-p-e-n #info-A {
  left: calc(5px + 1.15vw);
}

#mapcontainer #o-p-e-n #info-B {
  right: calc(5px + 1.15vw);
  text-align: right;
  word-spacing: 0.1em;
}

#mapcontainer #o-p-e-n #info-A, #mapcontainer #o-p-e-n #info-B {
  font-size: 0.7rem;
}

#mapcontainer #o-p-e-n #info-A span, #mapcontainer #o-p-e-n #info-B span {
  display: none;
}

@media screen and (min-width: 400px) {
  #mapcontainer #o-p-e-n #info-A span, #mapcontainer #o-p-e-n #info-B span {
    display: inline-block;
  }
}

@media screen and (min-width: 600px) {
  #mapcontainer #o-p-e-n #info-A, #mapcontainer #o-p-e-n #info-B {
    font-size: 1rem;
  }
}

#locations {
  position: fixed;
  top: 0;
  left: 32px;
  z-index: 997;
  width: calc(100vw - 32px);
  height: 100%;
  overflow: hidden;
  color: black;
  background-color: white;
  border-right: 1px solid black;
}

@media screen and (min-width: 600px) {
  #locations {
    width: calc((100vw - 32px) / 2);
  }
}

@media screen and (min-width: 900px) {
  #locations {
    width: calc((100vw - 32px) / 3);
  }
}

@media screen and (min-width: 1200px) {
  #locations {
    width: calc((100vw - 32px) / 4);
  }
}

@media screen and (min-width: 1600px) {
  #locations {
    width: calc((100vw - 32px) / 5);
  }
}

#locations .location {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#locations .location .scroll {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 50px);
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

#locations .location .scroll::-webkit-scrollbar {
  display: none;
}

#locations .location .scroll .wrap {
  width: calc(100vw - 32px);
}

@media screen and (min-width: 600px) {
  #locations .location .scroll .wrap {
    width: calc((100vw - 32px) / 2);
  }
}

@media screen and (min-width: 900px) {
  #locations .location .scroll .wrap {
    width: calc((100vw - 32px) / 3);
  }
}

@media screen and (min-width: 1200px) {
  #locations .location .scroll .wrap {
    width: calc((100vw - 32px) / 4);
  }
}

@media screen and (min-width: 1600px) {
  #locations .location .scroll .wrap {
    width: calc((100vw - 32px) / 5);
  }
}

#locations .header {
  display: flex;
  flex: 0 0 auto;
}

#locations .header div {
  position: relative;
  width: 50%;
  height: 50vw;
  padding: 10px;
}

#locations .header div:last-child {
  border-left: 1px solid black;
}

@media screen and (min-width: 600px) {
  #locations .header div {
    height: 25vw;
  }
}

@media screen and (min-width: 900px) {
  #locations .header div {
    height: 16.666vw;
  }
}

@media screen and (min-width: 1200px) {
  #locations .header div {
    height: 12.5vw;
  }
}

@media screen and (min-width: 1600px) {
  #locations .header div {
    height: 10vw;
  }
}

#locations .header div span {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30vw;
  height: 30vw;
  margin: -15vw 0 0 -15vw;
  border-radius: 100%;
  color: white;
  background-color: black;
  font-size: 12.5vw;
  line-height: 29vw;
  text-align: center;
}

@media screen and (min-width: 600px) {
  #locations .header div span {
    width: 16vw;
    height: 16vw;
    margin: -8vw 0 0 -8vw;
    font-size: 7.5vw;
    line-height: 15.25vw;
  }
}

@media screen and (min-width: 900px) {
  #locations .header div span {
    width: 12vw;
    height: 12vw;
    margin: -6vw 0 0 -6vw;
    font-size: 5.5vw;
    line-height: 11.5vw;
  }
}

@media screen and (min-width: 1200px) {
  #locations .header div span {
    width: 8.5vw;
    height: 8.5vw;
    margin: -4.25vw 0 0 -4.25vw;
    font-size: 4vw;
    line-height: 8.25vw;
  }
}

@media screen and (min-width: 1600px) {
  #locations .header div span {
    width: 7vw;
    height: 7vw;
    margin: -3.5vw 0 0 -3.5vw;
    font-size: 3.5vw;
    line-height: 6.85vw;
  }
}

#locations .content > div {
  width: 100%;
  height: auto;
  padding: 10px;
  border-top: 1px dotted rgba(0, 0, 0, 0.25);
}

#locations .content > div span.label {
  display: block;
  text-transform: uppercase;
  font-size: 0.7rem;
  letter-spacing: 0.035em;
  margin-bottom: 1rem;
}

#locations .content > div span.label .icon {
  padding-right: 0.25rem;
}

#locations .content > div p {
  margin-bottom: 1rem;
}

#locations .content > div p:last-child {
  margin-bottom: 0;
}

#locations .content > div.image {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 0 1 100%;
  height: 50vw;
  max-height: 480px;
  padding: 0;
  background-position: center center;
  background-repeat: norepeat;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

@media screen and (min-width: 900px) {
  #locations .content > div.image {
    height: 33.333vw;
  }
}

@media screen and (min-width: 1200px) {
  #locations .content > div.image {
    height: 25vw;
  }
}

@media screen and (min-width: 1600px) {
  #locations .content > div.image {
    height: 20vw;
  }
}

#locations .content > div.image a {
  display: block;
  top: 50%;
  left: 50%;
  width: auto;
  height: 2rem;
  border-radius: 1.5rem;
  padding: 0 0.85rem;
  color: white;
  background-color: black;
  font-size: 0.7rem;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  line-height: 2rem;
}

#locations .content > div.image a .icon {
  padding-right: 0.25rem;
}

#locations .content > div.image + div {
  border-top: none;
}

#program, #list, #press {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: calc(100vw - 32px);
  height: 100%;
  overflow: hidden;
  color: white;
  background-color: black;
  line-height: 1.05;
}

@media screen and (min-width: 600px) {
  #program, #list, #press {
    width: calc(50vw - 32px);
  }
}

@media screen and (min-width: 900px) {
  #program, #list, #press {
    width: calc(33.333vw - 32px);
  }
}

@media screen and (min-width: 1200px) {
  #program, #list, #press {
    width: calc(25vw - 32px);
  }
}

@media screen and (min-width: 1600px) {
  #program, #list, #press {
    width: calc(20vw - 32px);
  }
}

#program .scroll, #list .scroll, #press .scroll {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 50px);
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

#program .scroll::-webkit-scrollbar, #list .scroll::-webkit-scrollbar, #press .scroll::-webkit-scrollbar {
  display: none;
}

#program .scroll .wrap, #list .scroll .wrap, #press .scroll .wrap {
  padding: 10px;
  width: calc(100vw - 32px);
}

@media screen and (min-width: 600px) {
  #program .scroll .wrap, #list .scroll .wrap, #press .scroll .wrap {
    width: calc(50vw - 32px);
  }
}

@media screen and (min-width: 900px) {
  #program .scroll .wrap, #list .scroll .wrap, #press .scroll .wrap {
    width: calc(33.333vw - 32px);
  }
}

@media screen and (min-width: 1200px) {
  #program .scroll .wrap, #list .scroll .wrap, #press .scroll .wrap {
    width: calc(25vw - 32px);
  }
}

@media screen and (min-width: 1600px) {
  #program .scroll .wrap, #list .scroll .wrap, #press .scroll .wrap {
    width: calc(20vw - 32px);
  }
}

#program .scroll .wrap > span, #list .scroll .wrap > span, #press .scroll .wrap > span {
  display: block;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

#list ul li {
  position: relative;
  margin-bottom: 1rem;
}

#list ul li:last-child {
  margin-bottom: 0;
}

#list ul li span {
  display: block;
}

#list ul li span:last-child {
  font-size: 0.7rem;
  margin-top: 0.15rem;
}

#program .day-container {
  margin-bottom: 5rem;
}

#program .day-container > span {
  display: block;
  text-transform: uppercase;
  word-spacing: 0.1em;
  margin-bottom: 1.5rem;
}

#program .event {
  margin-bottom: 1.5rem;
}

#program .event > div:first-child {
  text-transform: uppercase;
}

#program .event > div:last-child {
  font-size: 0.7rem;
  line-height: 1.15;
  margin-top: 0.15rem;
}

#program .event > div strong {
  font-style: normal;
  text-decoration: underline;
}

#program .event > div p, #program .event > div ul, #program .event > div ol {
  max-width: 320px;
}

#press .wrap > div {
  margin-bottom: 2rem;
}

#press .wrap > div:last-child {
  margin-bottom: 0;
}

#press .downloads {
  font-size: 0.7rem;
  text-transform: uppercase;
}

#press .downloads a {
  display: inline-block;
  margin-bottom: 0.25rem;
}

#press .downloads a:last-child {
  margin-bottom: 0;
}

#press .downloads svg {
  margin-right: 0.25rem;
}

#press .contact {
  font-size: 0.7rem;
}

#press .about p {
  text-indent: 1.25rem;
}

#press .about p:first-child {
  text-indent: 0;
}

#press .about p a {
  text-decoration: underline;
}

#press .partners {
  margin-top: 5rem;
}

#press .partners div {
  margin-bottom: 1.5rem;
}

#press .partners img {
  display: inline-block;
  height: 44px;
  width: auto;
  margin-right: 1rem;
}

#press .partners #logo-brno {
  height: 22px;
  margin-bottom: 0.25rem;
}

#press .partners #logo-vut {
  height: 40px;
  margin-bottom: 0.25rem;
}

#press .partners p {
  font-size: 0.7rem;
}

body nav {
  transform: translateX(0);
  transition: transform 400ms cubic-bezier(0.59, 0.06, 0.32, 0.99);
}

body nav a#close, body nav ul {
  transition: transform 250ms 400ms, color 150ms;
}

body #mapcontainer .os-ltr, body #mapcontainer #info-A, body #mapcontainer #info-B {
  opacity: 1;
  transition: opacity 600ms 800ms;
}

body #locations {
  transform: translateX(-100%);
  transition: transform 600ms cubic-bezier(0.59, 0.06, 0.32, 0.99);
}

body #locations .location {
  opacity: 0;
  transition: opacity 200ms 200ms;
}

body #list, body #program, body #press {
  transform: translateX(-100%);
  pointer-events: none;
  transition: transform 400ms cubic-bezier(0.59, 0.06, 0.32, 0.99);
}

body.locationopen nav a#close,
body.listopen nav a#close,
body.programopen nav a#close,
body.pressopen nav a#close {
  transform: translateY(32px);
  transition: transform 200ms, color 150ms;
}

body.locationopen nav ul,
body.listopen nav ul,
body.programopen nav ul,
body.pressopen nav ul {
  transform: rotate(90deg) translateX(30px) translateY(-100%);
  transition: transform 200ms;
}

body.locationopen #mapcontainer .os-ltr, body.locationopen #mapcontainer #info-A, body.locationopen #mapcontainer #info-B,
body.listopen #mapcontainer .os-ltr,
body.listopen #mapcontainer #info-A,
body.listopen #mapcontainer #info-B,
body.programopen #mapcontainer .os-ltr,
body.programopen #mapcontainer #info-A,
body.programopen #mapcontainer #info-B,
body.pressopen #mapcontainer .os-ltr,
body.pressopen #mapcontainer #info-A,
body.pressopen #mapcontainer #info-B {
  opacity: 0;
  transition: opacity 250ms;
}

body.locationopen nav {
  transform: translateX(0);
  transition: transform 300ms cubic-bezier(0.59, 0.06, 0.32, 0.99);
}

body.locationopen #locations {
  transform: translateX(0%);
  transition: transform 400ms cubic-bezier(0.57, 0.01, 0.82, 0.99) 250ms;
}

body.locationopen #locations .location {
  transition: opacity 300ms;
}

body.locationopen #locations .location.open {
  opacity: 1;
  transition: opacity 800ms 350ms;
  pointer-events: auto;
}

body.locationopen #list, body.locationopen #program, body.locationopen #press {
  transition: transform 300ms cubic-bezier(0.59, 0.06, 0.32, 0.99);
}

body.listopen nav {
  transform: translateX(calc(100vw - 32px));
  transition: transform 500ms cubic-bezier(0.29, 0.24, 0.29, 0.99) 200ms;
}

@media screen and (min-width: 600px) {
  body.listopen nav {
    transform: translateX(calc(50vw - 32px));
  }
}

@media screen and (min-width: 900px) {
  body.listopen nav {
    transform: translateX(calc(33.333vw - 32px));
  }
}

@media screen and (min-width: 1200px) {
  body.listopen nav {
    transform: translateX(calc(25vw - 32px));
  }
}

@media screen and (min-width: 1600px) {
  body.listopen nav {
    transform: translateX(calc(20vw - 32px));
  }
}

body.listopen #mapcontainer .os-ltr, body.listopen #mapcontainer #info-A, body.listopen #mapcontainer #info-B {
  transition: opacity 250ms 150ms;
}

body.listopen #locations {
  transition: transform 200ms cubic-bezier(0.57, 0.01, 0.82, 0.99);
}

body.listopen #list {
  transform: translateX(0%);
  pointer-events: auto;
  transition: transform 500ms cubic-bezier(0.29, 0.24, 0.29, 0.99) 200ms;
}

body.listopen #program, body.listopen #press {
  transition: transform 300ms cubic-bezier(0.59, 0.06, 0.32, 0.99);
}

body.programopen nav {
  transform: translateX(calc(100vw - 32px));
  transition: transform 500ms cubic-bezier(0.29, 0.24, 0.29, 0.99) 200ms;
}

@media screen and (min-width: 600px) {
  body.programopen nav {
    transform: translateX(calc(50vw - 32px));
  }
}

@media screen and (min-width: 900px) {
  body.programopen nav {
    transform: translateX(calc(33.333vw - 32px));
  }
}

@media screen and (min-width: 1200px) {
  body.programopen nav {
    transform: translateX(calc(25vw - 32px));
  }
}

@media screen and (min-width: 1600px) {
  body.programopen nav {
    transform: translateX(calc(20vw - 32px));
  }
}

body.programopen #mapcontainer .os-ltr, body.programopen #mapcontainer #info-A, body.programopen #mapcontainer #info-B {
  transition: opacity 250ms 150ms;
}

body.programopen #locations {
  transition: transform 200ms cubic-bezier(0.57, 0.01, 0.82, 0.99);
}

body.programopen #program {
  transform: translateX(0%);
  pointer-events: auto;
  transition: transform 500ms cubic-bezier(0.29, 0.24, 0.29, 0.99) 200ms;
}

body.programopen #list, body.programopen #press {
  transition: transform 300ms cubic-bezier(0.59, 0.06, 0.32, 0.99);
}

body.pressopen nav {
  transform: translateX(calc(100vw - 32px));
  transition: transform 500ms cubic-bezier(0.29, 0.24, 0.29, 0.99) 200ms;
}

@media screen and (min-width: 600px) {
  body.pressopen nav {
    transform: translateX(calc(50vw - 32px));
  }
}

@media screen and (min-width: 900px) {
  body.pressopen nav {
    transform: translateX(calc(33.333vw - 32px));
  }
}

@media screen and (min-width: 1200px) {
  body.pressopen nav {
    transform: translateX(calc(25vw - 32px));
  }
}

@media screen and (min-width: 1600px) {
  body.pressopen nav {
    transform: translateX(calc(20vw - 32px));
  }
}

body.pressopen #mapcontainer .os-ltr, body.pressopen #mapcontainer #info-A, body.pressopen #mapcontainer #info-B {
  transition: opacity 250ms 150ms;
}

body.pressopen #locations {
  transition: transform 200ms cubic-bezier(0.57, 0.01, 0.82, 0.99);
}

body.pressopen #press {
  transform: translateX(0%);
  pointer-events: auto;
  transition: transform 500ms cubic-bezier(0.29, 0.24, 0.29, 0.99) 200ms;
}

body.pressopen #program, body.pressopen #list {
  transition: transform 300ms cubic-bezier(0.59, 0.06, 0.32, 0.99);
}

.marker {
  width: 38px;
  height: 38px;
  background-color: black;
  cursor: pointer;
}

.marker span {
  display: block;
  position: absolute;
  top: 7px;
  left: 7px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  font-family: 'FKG', 'Arial', sans-serif;
  font-feature-settings: "ss20" 1;
  font-weight: 700;
  font-size: 0.7rem;
  line-height: 22px;
  text-align: center;
}

.marker p {
  transform: translate(0, -22px);
  text-align: center;
}

.pswp__ui--fit .pswp__top-bar,
.pswp__button--arrow--left:before,
.pswp__button--arrow--right:before {
  background-color: transparent;
}

.pswp__img--placeholder--blank {
  background: transparent;
}

.pswp__preloader {
  top: 50%;
  left: 50%;
  margin: -22px 0 0 -22px;
}

.pswp--zoom-allowed .pswp__img {
  cursor: default;
}

.pswp__counter {
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
  font-size: 0.7rem;
  opacity: 1;
}

.pswp__button {
  opacity: 1;
}

.pswp__button--close {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -22px;
}

.pswp img {
  padding: 44px;
}
